@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.root {
  position: relative;

  @include devices(md-up) {
    padding: spacing(4);
  }
}

.icon {
  @include devices(md-up) {
    width: 54px;
    height: 54px;
  }
}

.title {
  font-size: rem(16);

  @include devices(md-up) {
    font-size: rem(19);
  }
}

.description {
  font-size: inherit;
  font-weight: inherit;

  &:first-child {
    margin-bottom: spacing(2);
  }
}

.link {
  color: var(--text-primary);
  text-decoration: underline;
}

.expertName {
  font-weight: var(--font-weight-bold);
}

.buttonWrapper {
  text-align: center;
  width: 100%;
}

.button {
  @include devices(md-up) {
    max-width: 300px;
    margin: spacing(0, 1);
  }
}

@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;
.copyright {
  opacity: 0.7;
  line-height: 1.57;
  font-size: rem(12);
  @include devices(md-up) {
    line-height: 2;
    font-size: rem(14);
  }
}
